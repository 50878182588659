/* Define the falling animation */

@keyframes rotateAndFall {
  0% {
    transform: translateY(-100vh) rotate(0deg);
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    transform: translateY(100vh) rotate(360deg);
    opacity: 0;
  }
}

.container {
  position: relative;
  height: 100vh;
  overflow: hidden;
}

.falling-image {
  position: absolute;
  animation: rotateAndFall linear infinite;
  animation-timing-function: ease-in-out;
  width: 100px;
  height: 100px;
  /* Animation duration and delay can be randomized via inline styles */
}

.left-section,
.right-section {
  top: 0;
  bottom: 0;
  width: 30%;
  flex: 1;
  @media screen and (max-width: 768px) {
    width: 0%;
  }
}

.left-section {
  left: 5px;
  flex: 1;
}

.right-section {
  right: 5px;
  flex: 1;
}

.picAndButton {
  display: inline-flex;
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    margin: 10px;
  }
}

.intro {
  font-size: 20px;
  margin-top: -200px;
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.external-links {
  display: flex;
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
}
.token-address {
  cursor: pointer;
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
}
