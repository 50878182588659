@import url("https://fonts.googleapis.com/css?family=Josefin+Sans");
@import url("https://fonts.googleapis.com/css?family=Encode+Sans+Expanded");
@import url("https://fonts.googleapis.com/css2?family=Bangers&family=Dokdo&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Bangers", system-ui;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 3px;
}

.logo-text {
  font: "Josefin Sans";
  color: #ceaf67;
}

.center-align {
  text-align: center;
}

.black-bg {
  background: white;
}

.white-bg {
  background: white;
}

.primary-bg {
  background: #b05ffd;
}

.h1-hero {
  color: Black;
  font-size: 48px;
  text-align: center;
  font-weight: bold;

  @media screen and (max-width: 768px) {
    font-size: 40px;
  }

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
}

.hero-text {
  color: black;
  font-size: 24px;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 15px;
  }
}

.img-wrap {
  height: 100%;
  max-width: 700px;
  margin: auto;
}

.fit-img {
  width: 100%;
}

.expand-row {
  width: 100%;
}

.white-text {
  color: white;
}

.black-text {
  color: black;
}

.gray-text {
  color: gray;
}

.pl {
  padding-left: 20px;
}

.pr {
  padding-right: 20px;
}
