.picture-wall {
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(100px, 1fr)
  ); /* Adjust the min size for responsiveness */
  gap: 10px; /* Space between images */
  padding: 10px;

  @media screen and (max-width: 768px) {
    display: none;
  }
}

.picture-item {
  overflow: hidden; /* Optional, in case you want to add more styles or effects like zoom */
}

.picture-item img {
  width: 100%; /* Make images fill their container */
  height: auto; /* Keep the original aspect ratio */
  display: block; /* Remove any space below the image */
}
