@import url(https://fonts.googleapis.com/css?family=Josefin+Sans);
@import url(https://fonts.googleapis.com/css?family=Encode+Sans+Expanded);
@import url(https://fonts.googleapis.com/css2?family=Bangers&family=Dokdo&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Bangers", system-ui;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 3px;
}

.logo-text {
  font: "Josefin Sans";
  color: #ceaf67;
}

.center-align {
  text-align: center;
}

.black-bg {
  background: white;
}

.white-bg {
  background: white;
}

.primary-bg {
  background: #b05ffd;
}

.h1-hero {
  color: Black;
  font-size: 48px;
  text-align: center;
  font-weight: bold;

  @media screen and (max-width: 768px) {
    font-size: 40px;
  }

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
}

.hero-text {
  color: black;
  font-size: 24px;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 15px;
  }
}

.img-wrap {
  height: 100%;
  max-width: 700px;
  margin: auto;
}

.fit-img {
  width: 100%;
}

.expand-row {
  width: 100%;
}

.white-text {
  color: white;
}

.black-text {
  color: black;
}

.gray-text {
  color: gray;
}

.pl {
  padding-left: 20px;
}

.pr {
  padding-right: 20px;
}

/* Define the falling animation */

@keyframes rotateAndFall {
  0% {
    transform: translateY(-100vh) rotate(0deg);
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    transform: translateY(100vh) rotate(360deg);
    opacity: 0;
  }
}

.container {
  position: relative;
  height: 100vh;
  overflow: hidden;
}

.falling-image {
  position: absolute;
  animation: rotateAndFall linear infinite;
  animation-timing-function: ease-in-out;
  width: 100px;
  height: 100px;
  /* Animation duration and delay can be randomized via inline styles */
}

.left-section,
.right-section {
  top: 0;
  bottom: 0;
  width: 30%;
  flex: 1 1;
  @media screen and (max-width: 768px) {
    width: 0%;
  }
}

.left-section {
  left: 5px;
  flex: 1 1;
}

.right-section {
  right: 5px;
  flex: 1 1;
}

.picAndButton {
  display: inline-flex;
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    margin: 10px;
  }
}

.intro {
  font-size: 20px;
  margin-top: -200px;
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.external-links {
  display: flex;
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
}
.token-address {
  cursor: pointer;
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
}

.picture-wall {
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(100px, 1fr)
  ); /* Adjust the min size for responsiveness */
  grid-gap: 10px;
  gap: 10px; /* Space between images */
  padding: 10px;

  @media screen and (max-width: 768px) {
    display: none;
  }
}

.picture-item {
  overflow: hidden; /* Optional, in case you want to add more styles or effects like zoom */
}

.picture-item img {
  width: 100%; /* Make images fill their container */
  height: auto; /* Keep the original aspect ratio */
  display: block; /* Remove any space below the image */
}

.icon {
  height: 25px;
  width: 25px;
  display: block;
  /* Other styles here */
}

@keyframes flashColors {
  0% {
    color: red;
  }
  25% {
    color: blue;
  }
  50% {
    color: green;
  }
  75% {
    color: yellow;
  }
  100% {
    color: red;
  }
}
.flashing-text {
  animation: flashColors 2s infinite;
}
.not-flashing-text {
  color: red;
}

div {
  font-size: 15px !important;
}

