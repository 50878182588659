.icon {
  height: 25px;
  width: 25px;
  display: block;
  /* Other styles here */
}

@keyframes flashColors {
  0% {
    color: red;
  }
  25% {
    color: blue;
  }
  50% {
    color: green;
  }
  75% {
    color: yellow;
  }
  100% {
    color: red;
  }
}
.flashing-text {
  animation: flashColors 2s infinite;
}
.not-flashing-text {
  color: red;
}
